<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                저장중인 스페이스가 있습니다.<br />
                이어서 등록하시겠습니까?
            </h3>
            <div class="single-file-btn">
                <p @click="newInsert()">아니오</p>
                <p id="color-text" @click="nextInsert()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';

export default defineComponent({
  name: 'addSpace',
  methods: {
    nextInsert () {
      var spaceId = VueCookies.get('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          var spaceType = res.data.result.spaceType;
          if (spaceType === 'Store') {
            this.$emit('closeAddSpace');
            router.push({ name: 'SpaceInsertStore', params: { spaceId } });
          } else {
            this.$emit('closeAddSpace');
            router.push({ name: 'SpaceInsertCar', params: { spaceId } });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newInsert () {
      var spaceId = VueCookies.get('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/deleteMySpaceInfo', { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.$emit('closeAddSpace');
          VueCookies.remove('spaceId');
          VueCookies.remove('spaceNm');
          router.push({ name: 'SpaceInsert' });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
});
</script>
